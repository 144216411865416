/**
 * Web services pour la collecte KYC
 * Web Services REST SOCREDO pour la collecte KYC * 0.01 : WS initial
 *
 * The version of the OpenAPI document: 0.01
 * Contact: info@piti.pf
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpContext, HttpEvent, HttpHeaders, HttpParameterCodec, HttpParams, HttpResponse} from '@angular/common/http';
import {CustomHttpParameterCodec} from '../conf/encoder';
import {Observable} from 'rxjs';

// @ts-ignore
import {DossierClientEtape1Identite} from '../model/dossierClientEtape1Identite';
// @ts-ignore
import {Erreur} from '../model/erreur';
// @ts-ignore
import {EtatCompletude} from '../model/etatCompletude';
// @ts-ignore
import {InfosDossierClientEtape1} from '../model/infosDossierClientEtape1';
// @ts-ignore
import {InfosDossierClientEtape2} from '../model/infosDossierClientEtape2';
// @ts-ignore
import {MajDossierClientEtape1} from '../model/majDossierClientEtape1';
// @ts-ignore
import {MajDossierClientEtape2} from '../model/majDossierClientEtape2';

// @ts-ignore
import {BASE_PATH, COLLECTION_FORMATS} from '../conf/variables';
import {Configuration} from '../conf/configuration';
import {Referentiel} from '../model/referentiel';
import {InfosEtape1Identite} from '../model/infosEtape1Identite';


@Injectable({
  providedIn: 'root'
})
export class DossierClientApiService {

  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;
  protected basePath = 'https://hoba-tests.socredo.pf/hoba3/api';

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  /**
   * R�cup�re l\&#39;�tat de compl�tude
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public etatCompletude(observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<EtatCompletude>;

  public etatCompletude(observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpResponse<EtatCompletude>>;

  public etatCompletude(observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpEvent<EtatCompletude>>;

  public etatCompletude(observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<any> {

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (BearerAuth) required
    localVarCredential = this.configuration.lookupCredential('BearerAuth');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json'
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }


    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.get<EtatCompletude>(`${this.configuration.basePath}/dossierClient/etatCompletude`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * R�cup�re les infos du dossier client en cours (�tape 1)
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public etape1(observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<InfosDossierClientEtape1>;
  public etape1(observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpResponse<InfosDossierClientEtape1>>;
  public etape1(observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpEvent<InfosDossierClientEtape1>>;
  public etape1(observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<any> {

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (BearerAuth) required
    localVarCredential = this.configuration.lookupCredential('BearerAuth');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json'
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }


    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.get<InfosDossierClientEtape1>(`${this.configuration.basePath}/dossierClient/etape1`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Envoi des pi�ces justificative pour l\&#39;identit�
   * @param infosIdentite
   * @param justificatifIdentite1
   * @param justificatifIdentite2
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public etape1Identite(infosIdentite: InfosEtape1Identite, justificatifIdentite1: Blob, justificatifIdentite2?: Blob, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<DossierClientEtape1Identite>;
  public etape1Identite(infosIdentite: InfosEtape1Identite, justificatifIdentite1: Blob, justificatifIdentite2?: Blob, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpResponse<DossierClientEtape1Identite>>;
  public etape1Identite(infosIdentite: InfosEtape1Identite, justificatifIdentite1: Blob, justificatifIdentite2?: Blob, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpEvent<DossierClientEtape1Identite>>;
  public etape1Identite(infosIdentite: InfosEtape1Identite, justificatifIdentite1: Blob, justificatifIdentite2?: Blob, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<any> {
    if (infosIdentite === null || infosIdentite === undefined) {
      throw new Error('Required parameter infosIdentite was null or undefined when calling etape1Identite.');
    }
    if (justificatifIdentite1 === null || justificatifIdentite1 === undefined) {
      throw new Error('Required parameter justificatifIdentite1 was null or undefined when calling etape1Identite.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (BearerAuth) required
    localVarCredential = this.configuration.lookupCredential('BearerAuth');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json'
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'multipart/form-data'
    ];

    const canConsumeForm = this.canConsumeForm(consumes);

    let localVarFormParams: { append(param: string, value: any): any; };
    let localVarUseForm = false;
    let localVarConvertFormParamsToString = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    localVarUseForm = canConsumeForm;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    localVarUseForm = canConsumeForm;
    if (localVarUseForm) {
      localVarFormParams = new FormData();
    } else {
      localVarFormParams = new HttpParams({encoder: this.encoder});
    }

    if (infosIdentite !== undefined) {
      localVarFormParams = localVarFormParams.append('infosIdentite', localVarUseForm ? new Blob([JSON.stringify(infosIdentite)], {type: 'application/json'}) : <any>infosIdentite) as any || localVarFormParams;
    }
    if (justificatifIdentite1 !== undefined) {
      localVarFormParams = localVarFormParams.append('justificatifIdentite1', <any>justificatifIdentite1) as any || localVarFormParams;
    }
    if (justificatifIdentite2 !== undefined) {
      localVarFormParams = localVarFormParams.append('justificatifIdentite2', <any>justificatifIdentite2) as any || localVarFormParams;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.post<DossierClientEtape1Identite>(`${this.configuration.basePath}/dossierClient/etape1/identite`,
      localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * R�cup�re les localit�s
   * @param codePays
   * @param codeDepartement
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public localites(codePays: string, codeDepartement?: string, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<Array<Referentiel>>;
  public localites(codePays: string, codeDepartement?: string, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpResponse<Array<Referentiel>>>;
  public localites(codePays: string, codeDepartement?: string, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpEvent<Array<Referentiel>>>;
  public localites(codePays: string, codeDepartement?: string, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<any> {
    if (codePays === null || codePays === undefined) {
      throw new Error('Required parameter codePays was null or undefined when calling localites.');
    }

    let localVarQueryParameters = new HttpParams({encoder: this.encoder});
    if (codePays !== undefined && codePays !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>codePays, 'codePays');
    }
    if (codeDepartement !== undefined && codeDepartement !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>codeDepartement, 'codeDepartement');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (BearerAuth) required
    localVarCredential = this.configuration.lookupCredential('BearerAuth');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json'
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }


    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.get<Array<Referentiel>>(`${this.configuration.basePath}/dossierClient/etape1/localites`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * R�cup�re les infos du dossier client en cours (�tape 2)
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public etape2(observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<InfosDossierClientEtape2>;
  public etape2(observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpResponse<InfosDossierClientEtape2>>;
  public etape2(observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpEvent<InfosDossierClientEtape2>>;
  public etape2(observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<any> {

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (BearerAuth) required
    localVarCredential = this.configuration.lookupCredential('BearerAuth');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json'
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }


    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.get<InfosDossierClientEtape2>(`${this.configuration.basePath}/dossierClient/etape2`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Mise � jour de l\&#39;�tape 1
   * @param etape1
   * @param justificatifDomicile
   * @param justificatifCitoyenAmericain
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public majEtape1(etape1: MajDossierClientEtape1, justificatifDomicile?: Blob, justificatifCitoyenAmericain?: Blob, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<any>;
  public majEtape1(etape1: MajDossierClientEtape1, justificatifDomicile?: Blob, justificatifCitoyenAmericain?: Blob, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpResponse<any>>;
  public majEtape1(etape1: MajDossierClientEtape1, justificatifDomicile?: Blob, justificatifCitoyenAmericain?: Blob, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpEvent<any>>;
  public majEtape1(etape1: MajDossierClientEtape1, justificatifDomicile?: Blob, justificatifCitoyenAmericain?: Blob, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<any> {
    if (etape1 === null || etape1 === undefined) {
      throw new Error('Required parameter etape1 was null or undefined when calling majEtape1.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (BearerAuth) required
    localVarCredential = this.configuration.lookupCredential('BearerAuth');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json'
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'multipart/form-data'
    ];

    const canConsumeForm = this.canConsumeForm(consumes);

    let localVarFormParams: { append(param: string, value: any): any; };
    let localVarUseForm = false;
    let localVarConvertFormParamsToString = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    localVarUseForm = canConsumeForm;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    localVarUseForm = canConsumeForm;
    if (localVarUseForm) {
      localVarFormParams = new FormData();
    } else {
      localVarFormParams = new HttpParams({encoder: this.encoder});
    }

    if (etape1 !== undefined) {
      localVarFormParams = localVarFormParams.append('etape1', localVarUseForm ? new Blob([JSON.stringify(etape1)], {type: 'application/json'}) : <any>etape1) as any || localVarFormParams;
    }
    if (justificatifDomicile !== undefined) {
      localVarFormParams = localVarFormParams.append('justificatifDomicile', <any>justificatifDomicile) as any || localVarFormParams;
    }
    if (justificatifCitoyenAmericain !== undefined) {
      localVarFormParams = localVarFormParams.append('justificatifCitoyenAmericain', <any>justificatifCitoyenAmericain) as any || localVarFormParams;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.put<any>(`${this.configuration.basePath}/dossierClient/etape1`,
      localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Mise � jour de l\&#39;�tape 2
   * @param etape2
   * @param justificatifRevenus
   * @param justificatifChiffreAffaire
   * @param justificatifCommerce
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public majEtape2(etape2: MajDossierClientEtape2, justificatifRevenus?: Array<Blob>, justificatifChiffreAffaire?: Blob, justificatifCommerce?: Blob, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<any>;
  public majEtape2(etape2: MajDossierClientEtape2, justificatifRevenus?: Array<Blob>, justificatifChiffreAffaire?: Blob, justificatifCommerce?: Blob, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpResponse<any>>;
  public majEtape2(etape2: MajDossierClientEtape2, justificatifRevenus?: Array<Blob>, justificatifChiffreAffaire?: Blob, justificatifCommerce?: Blob, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpEvent<any>>;
  public majEtape2(etape2: MajDossierClientEtape2, justificatifRevenus?: Array<Blob>, justificatifChiffreAffaire?: Blob, justificatifCommerce?: Blob, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<any> {
    if (etape2 === null || etape2 === undefined) {
      throw new Error('Required parameter etape2 was null or undefined when calling majEtape2.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (BearerAuth) required
    localVarCredential = this.configuration.lookupCredential('BearerAuth');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json'
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'multipart/form-data'
    ];

    const canConsumeForm = this.canConsumeForm(consumes);

    let localVarFormParams: { append(param: string, value: any): any; };
    let localVarUseForm = false;
    let localVarConvertFormParamsToString = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    localVarUseForm = canConsumeForm;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    localVarUseForm = canConsumeForm;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    localVarUseForm = canConsumeForm;
    if (localVarUseForm) {
      localVarFormParams = new FormData();
    } else {
      localVarFormParams = new HttpParams({encoder: this.encoder});
    }

    if (etape2 !== undefined) {
      localVarFormParams = localVarFormParams.append('etape2', localVarUseForm ? new Blob([JSON.stringify(etape2)], {type: 'application/json'}) : <any>etape2) as any || localVarFormParams;
    }
    if (justificatifRevenus) {
      if (localVarUseForm) {
        justificatifRevenus.forEach((element) => {
          localVarFormParams = localVarFormParams.append('justificatifRevenus', <any>element) as any || localVarFormParams;
        })
      } else {
        localVarFormParams = localVarFormParams.append('justificatifRevenus', justificatifRevenus.join(COLLECTION_FORMATS['csv'])) as any || localVarFormParams;
      }
    }
    if (justificatifChiffreAffaire !== undefined) {
      localVarFormParams = localVarFormParams.append('justificatifChiffreAffaire', <any>justificatifChiffreAffaire) as any || localVarFormParams;
    }
    if (justificatifCommerce !== undefined) {
      localVarFormParams = localVarFormParams.append('justificatifCommerce', <any>justificatifCommerce) as any || localVarFormParams;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.put<any>(`${this.configuration.basePath}/dossierClient/etape2`,
      localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key,
            (value as Date).toISOString().substr(0, 10));
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(
          httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

}
