import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-simple-popup',
  templateUrl: './simple-popup.component.html',
  styleUrls: ['./simple-popup.component.css'],
})
export class SimplePopupComponent implements OnInit {

  /** le texte � afficher */
  @Input() bodyText: string;

  /** fermeture de la modal */
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  static show() {
    const simpleModal = $('#simple-popup');
    if (!simpleModal.hasClass('js-active')) {
      simpleModal.addClass('js-active');
    }
  }

  static hide() {
    const simpleModal = $('#simple-popup');
    if (simpleModal.hasClass('js-active')) {
      simpleModal.removeClass('js-active');
    }
  }

  constructor() {
  }

  ngOnInit() {
  }

}
