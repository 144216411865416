import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ErreursService} from './erreurs.service';
import {CoreServiceModule} from '../core-service.module';
import {DemandeAnr} from '../../api/model/demandeAnr';
import {Observer} from 'rxjs';
import {NGXLogger} from 'ngx-logger';

@Injectable({
  providedIn: CoreServiceModule,
})
export abstract class BaseService {

  public static readonly ANR_DEMO: DemandeAnr = {
    type: 'ANR',
    cleAnr: 'demo',
    telephoneMasque: '99xxxx99'

  };
  private static readonly CODE_HTTP_AFFICHABLES: number[] = [401, 404, 422, 428, 424, 503];

  protected constructor(protected erreursService: ErreursService, protected readonly logger: NGXLogger) {
  }

  /**
   * Construit une erreur destin�e au client
   *
   * @param httpErrorResponse l'erreur http
   * @param message la premi�re partie du message
   */
  errorBuilder(httpErrorResponse: HttpErrorResponse, message?: string): Error {
    const error: Error = new Error();
    error.name = '' + httpErrorResponse.status;
    // si l'erreur fait partie des CODES affichables, on prend le message du WS
    if (BaseService.CODE_HTTP_AFFICHABLES.indexOf(httpErrorResponse.status) !== -1) {
      if (typeof httpErrorResponse.error === 'string') {
        error.message = httpErrorResponse.error;
      } else if (httpErrorResponse.error.message && typeof httpErrorResponse.error.message === 'string') {
        error.message = httpErrorResponse.error.message;
      }

      // on traite l'erreur 422 en popup
      if (httpErrorResponse.status === 422) {
        this.erreursService.traiteErreur(error.message);
        error.message = null;
      }

      return error;
    }
    // sinon on affiche le message fourni par le service
    if (message) {
      error.message = message;
      return error;
    }
    // sinon, on affiche ce message par d�faut
    return new Error('Une erreur est survenue dans l\'application');
  }

  generePromiseDemandeAnrDemo(): Promise<DemandeAnr> {
    return Promise.resolve(BaseService.ANR_DEMO);
  }

  public dowloadFile(file: Blob, fileName: string, mimeType?: string): void {
    // https://stackoverflow.com/questions/52154874/angular-6-downloading-file-from-rest-api/52687792
    const blob = new Blob([file], {type: mimeType});

    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');

    anchor.download = fileName;
    anchor.href = url;
    // this is necessary as link.click() does not work on the latest firefox
    anchor.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));

    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(url);
      anchor.remove();
    }, 500);
  }

  public parseFilenameFromContentDisposition(contentDisposition: string | null): string {
    if (!contentDisposition) {
      return '';
    }
    const matches = /filename="(.*)"/g.exec(contentDisposition);
    return matches && matches.length > 1 ? matches[1] : '';
  }

  /**
   * Log le r�sultat de l'op�ration en success ou error
   *
   * @param message message � afficher dans le log
   * @protected
   */
  protected logResult<T>(message: string): Observer<T> {
    return {
      next: (x: any) => this.logger.debug('Succeed: ' + message, x),
      error: (err: any) => this.logger.error('Error: ' + message, err),
      complete: () => null,
    };
  }


}
